import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const ConfirmationModal = (props) => {

  const { text, text2, accept, cancel, isOpen, toggleModal, cancelText } = props

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} style={{padding: "10px"}} centered={true} >
      <ModalHeader className="border-0 h2-title justify-content-center">Confirmation</ModalHeader>
      <ModalBody>
        {text2 && <div className="text-left generel-text-1 pb-2 pl-3" style={{lineHeight: 1.2}}>
          {text2}
        </div>}
        <div style={{lineHeight: 1.2}} className="text-center generel-text-1 pr-2 pl-2">
          {text}
        </div>
        <div className="text-center mt-4 pb-4">
          <Button onClick={cancel} className="btn btn-danger mr-4">
            { cancelText ? cancelText : 'No'}
          </Button>
          <Button onClick={accept} color="info" type="submit">
            Yes
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal;